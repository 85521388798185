import { createSelector } from '@reduxjs/toolkit'
import type { GameProduct } from '@patrianna/shared-patrianna-types/store/GameModule'
import type { ReduxState } from 'store/types'
import { getActiveCurrencyIdSelector } from 'store/modules/currencies/selectors'
import { getAllGamesProductsSelector } from 'store/modules/games/selectors'
import { getGoldenKeySelector } from 'store/modules/user/selectors'
import { getFreeSpinsSelector } from 'store/modules/freeSpins/selectors'
import { isGoldenKeyGameProduct } from 'utils/games'

const PAUSE_PERIOD = 5
const MINUTES = 60 * 1000

const getActiveGameCode = (state: ReduxState) => state.slotGameFlow.activeGameCode
const getActiveGameTags = (state: ReduxState) => state.slotGameFlow.activeGameTags
const getLowOnCoinsLastDateOpened = (state: ReduxState) => state.slotGameFlow.lowOnCoinsLastDateOpened

export const getGoldMinBet = (state: ReduxState) => state.slotGameFlow.goldMinBet || 50
export const getSweepstakeMinBet = (state: ReduxState) => state.slotGameFlow.sweepstakeMinBet || 0.25

export const getActiveGameCodeSelector = createSelector(getActiveGameCode, (gameCode) => gameCode)

export const getActiveSlotSelector = createSelector(
  getAllGamesProductsSelector,
  getActiveGameCodeSelector,
  (games, gameCode) => {
    return games?.find((game) => game.code === gameCode)
  }
)

export const showGameNotForScDialogSelector = createSelector(
  getActiveCurrencyIdSelector,
  getGoldenKeySelector,
  (_: ReduxState, currentGame: GameProduct) => currentGame,
  (currency, goldenKey, game) => {
    const isGoldMode = isGoldenKeyGameProduct(game)
    const onlyGoldMode = isGoldMode && currency === 'SC'

    return onlyGoldMode || (!goldenKey && isGoldMode)
  }
)

export const getMinBetSelector = createSelector(
  getActiveCurrencyIdSelector,
  getGoldMinBet,
  getSweepstakeMinBet,
  (activeCurrency, goldMinBet, sweepstakeMinBet) => {
    return activeCurrency === 'GC' ? goldMinBet : sweepstakeMinBet
  }
)

export const getLowOnCoinsDialogDelaySelector = createSelector(
  getLowOnCoinsLastDateOpened,
  (_: ReduxState, currentTime: number) => currentTime,
  (lastDateOpened, currentTime) => {
    if (lastDateOpened) {
      const differenceInTime = currentTime - new Date(lastDateOpened).getTime()
      const differenceInMinutes = differenceInTime / MINUTES

      return differenceInMinutes >= PAUSE_PERIOD
    }

    return true
  }
)

export const isLiveCasinoGameLaunchedSelector = createSelector(getActiveGameTags, (gameTags) =>
  gameTags.includes('LIVE')
)

export const getIsActiveGameHasFreeSpins = createSelector(
  getFreeSpinsSelector,
  getActiveGameCodeSelector,
  getActiveCurrencyIdSelector,
  (freeSpins, activeGameCode, activeCurrency) => {
    return freeSpins.some(
      (freeSpin) =>
        freeSpin.currency === activeCurrency && freeSpin.products.some((game) => game.code === activeGameCode)
    )
  }
)
