'use client'

import { createContext, memo, useContext, useMemo } from 'react'
import getCountryConfig from 'app/utils/country'
import { useAppSelector } from 'store/hooks'
import { isLoggedInSelector } from 'store/modules/user/selectors'
import { getCookie } from '@patrianna/shared-utils/cookie'
import { USER_COUNTRY, LOCATION_USER_COUNTRY } from '@patrianna/shared-utils/constants/userCountry'
import type { Country } from 'app/utils/country/types'
import type { ReactNode } from 'react'

const CountryConfigContext = createContext<Country>(null)
CountryConfigContext.displayName = 'CountryConfigContext'

interface Props {
  children: ReactNode
  countryCode: string
  locationCountryCode: string | null
}

function CountryConfigProvider(props: Props) {
  const { children, countryCode, locationCountryCode } = props
  const isLoggedIn = useAppSelector(isLoggedInSelector)

  const memoizedCountryInfo = useMemo(() => {
    const userCountryCode = isLoggedIn ? getCookie(USER_COUNTRY, document.cookie) : countryCode
    const userLocationCountryCode = isLoggedIn ? getCookie(LOCATION_USER_COUNTRY, document.cookie) : locationCountryCode
    const countryConfig = getCountryConfig(userCountryCode)

    return {
      ...countryConfig,
      countryCode: userCountryCode,
      locationCountryCode: userLocationCountryCode,
    }
  }, [isLoggedIn, countryCode, locationCountryCode])

  return <CountryConfigContext.Provider value={memoizedCountryInfo}>{children}</CountryConfigContext.Provider>
}

const CountryConfigProviderMemo = memo(CountryConfigProvider)

function useCountryConfig() {
  const country = useContext(CountryConfigContext)

  if (country === undefined) {
    throw new Error('useCountryConfig must be used within a CountryProvider')
  }

  return country
}

export { CountryConfigProviderMemo as CountryConfigProvider, useCountryConfig }
