import { createSelector } from '@reduxjs/toolkit'
import { isPast } from 'date-fns'
import type { ReduxState } from 'store/types'

const getFreeSpins = (state: ReduxState) => state.freeSpins.freeSpins

export const getFreeSpinsSelector = createSelector(getFreeSpins, (freeSpins) =>
  freeSpins.filter(
    // field .fromWS will be added to packages after successful testing
    // @ts-ignore
    (freeSpin) => !isPast(new Date(freeSpin.expireAt)) && freeSpin.rounds > freeSpin.roundsPlayed && !freeSpin.fromWS
  )
)
