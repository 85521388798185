import { DEFAULT_COUNTRY_CODE } from './constants'
import { type CountryConfig } from './types'
import allCountryConfigs from './config'

/**
 * This is a function return config with options specific to the user country by country code.
 * Can be used on both client and server component.
 *
 * @param {string} countryCode - country code in ISO 3166 format. For example: US, NZ, UA
 * @return {Object} CountryConfig
 *
 * @example
 *
 *     const { socialLinksFeature } = getCountryConfig('US')
 */

export default function getCountryConfig(countryCode: string): CountryConfig {
  const hasCountryConfig = Object.prototype.hasOwnProperty.call(allCountryConfigs, countryCode)
  const multiCountryConfigKey = hasCountryConfig ? countryCode : 'no-country'
  const countryConfigKey = process.env.MULTI_COUNTRY_SUPPORT ? multiCountryConfigKey : DEFAULT_COUNTRY_CODE
  const userCountryConfig = allCountryConfigs[countryConfigKey]

  return userCountryConfig
}
