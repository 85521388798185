import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import type { SessionDialogs } from '@patrianna/shared-patrianna-types/store/SessionDialogsModule'
import type { DialogNames, SeesionDialogsStore } from 'store/types'
import { getCurrentOrDefault } from 'utils/getCurrentOrDefault'

const initialState: SeesionDialogsStore = {
  socialMediaReward: false,
  legalUpdate: false,
  dailyBonuses: false,
  myStashWin: false,
  offers: false,
  freeSpins: false,
  historySessionDialogs: [],
}

export const sessionDialogsSlice = createSlice({
  name: 'sessionDialogs',
  initialState,
  reducers: {
    waitingForSessionDialogsData: (state, action: PayloadAction<SessionDialogs>) => {
      state.offers = getCurrentOrDefault(action.payload.offers, state.offers)
      state.legalUpdate = getCurrentOrDefault(action.payload.legalUpdate, state.legalUpdate)
      state.dailyBonuses = getCurrentOrDefault(action.payload.dailyBonuses, state.dailyBonuses)
      state.myStashWin = getCurrentOrDefault(action.payload.myStashWin, state.myStashWin)
      state.freeSpins = getCurrentOrDefault(action.payload.freeSpins, state.freeSpins)
      state.socialMediaReward = getCurrentOrDefault(action.payload.socialMediaReward, state.socialMediaReward)
    },

    setHistorySessionDialogs: (state, action: PayloadAction<{ modalName: DialogNames }>) => {
      const uniqueItems = [...new Set([...state.historySessionDialogs, action.payload.modalName])]
      state.historySessionDialogs = uniqueItems
    },

    removeFromHistorySessionDialogs: (state, action: PayloadAction<{ modalName: DialogNames }>) => {
      state.historySessionDialogs = state.historySessionDialogs.filter((dialog) => dialog !== action.payload.modalName)
    },
  },
})

export type ActionsTypes = ReturnType<InferValueTypes<typeof sessionDialogsSlice.actions>>
export const { actions } = sessionDialogsSlice
